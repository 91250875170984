  .fileTitle {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .previewContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    span[id="close"] {
      cursor: pointer;
    }
  }
  
  .innerContainer {
    flex-direction: row;
    display: flex;
  }
  
  .formContainer {
    flex-direction: column;
    padding-left: 15px;
  }
  
  .padding {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  