.pointerFolder {
  width: min-content;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
  font-weight: 100;
}

.empty {
  &_folder {
    width: 175px;
  }

  &_line {
    &_upper {
      font-weight: bold;
    }
    text-align: center;
  }
}

.entry {
  &_name {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: none !important;
    font-family: var(--text-font);
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    color: var(--text-color);
    letter-spacing: normal;
  }
}

.button {
  width: 100%;
  height: 100%;
  text-align: start !important;
  &_fixed_icon {
    height: 15px;
    width: 15px;
    margin-left: 4px;
    margin-top: 1px;
    margin-right: 4px;
  }
  &_height {
    margin-top: -2px !important;
  }
  &_width {
    width: unset;
    &:focus {
      box-shadow: none !important;
    }
  }
  &_hover {
    padding: 0px !important;
    border: none;
    &:active {
      border: none;
      box-shadow: none !important;
    }
    &:focus {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
    &:hover {
      background-color: none !important;
    }
  }
}

.bold {
  font-weight: bold;
}

.dropdown {
  position: unset !important;
  button {
    padding-top: 2px;
  }
}

.width_fix_gridcell {
  margin-left: 21px !important;
}
.overflow_fix {
  overflow: unset !important;
}
.height_fix_gridcell {
  padding-top: 0 !important;
}
.float_right {
  justify-content: flex-end;
}
.float_left {
  div[class="totaal-container"] {
    justify-content: flex-start !important;
  }
}
.warning {
  &_btn {
    button {
      background-color: #dc3545;
    }
  }

  &_input {
    color: var(--validation-red-text);
    input {
      border: 2px solid var(--validation-red-border) !important;
    }
  }
}

.inactive {
  button[id="btnopslaan"] {
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
}

.download_button {
  padding-left: 10px;
  &_disabled {
    cursor: not-allowed;
  }
}

.height_fix_labeledText {
  margin-top: 4px;
}
.width_fix_labeledText {
  div {
    div {
      div {
        justify-content: unset !important;
      }
    }
  }
}

.voorvertoning {
  padding-top: 30px;
}
